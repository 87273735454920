































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { mixins } from 'vue-class-component';
import { RootState } from '@/store/store';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import BaseTable from '@/components/BaseTable.vue';
import { AuditLog } from '@/store/modules/audit-log/types/audit-log.types';
import dayjs from 'dayjs';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import AuditLogModal from './AuditLogModal.vue';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { moneyParser } from '@/utils/parser.util';
import { get as _get } from 'lodash';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import Receipt from '../../Order/components/Receipt.vue';
import { PaymentStatusTypes } from '../../../store/types/general.types';
import { downloadReceiptPDF } from '@/Export/ReceiptPDFGenerator';

@Component({
  components: {
    BaseTable,
    SortableTableHeader,
    BasePagination,
    BaseLoading,
    TableActionDropdown,
    Receipt
  }
})
export default class AuditLogList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Order No.', sortColumn: 'orderId', sort: true },
    {
      placeholder: 'Customer',
      sortColumn: 'username',
      sort: true
    },
    { placeholder: 'Product', sortColumn: 'productName', sort: true },
    { placeholder: 'Price', sortColumn: 'productPrice', sort: true },
    { placeholder: 'GST', sort: false },
    { placeholder: 'Total Price', sortColumn: 'totalPrice', sort: true },
    { placeholder: 'Date', sortColumn: 'startDate', sort: true },
    { placeholder: 'Status', sort: false }
  ];
  @Prop() public items!: AuditLog[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
  @Prop() public isDownload!: boolean | null;
  isDownloadingReceipt = false;
  receipt: AuditLog | undefined;

  @State(({ auditLog }: RootState) => auditLog.apiState.getAuditLogs.success)
  public getAuditLogSuccess!: boolean;

  public getTransactionStatus(paymentStatus: PaymentStatusTypes) {
    return paymentStatus === PaymentStatusTypes.SUCCESS
      ? 'Successful'
      : paymentStatus === PaymentStatusTypes.FAILED
      ? 'Failed'
      : 'Pending';
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  public getAuditLog(auditLogId: number) {
    this.receipt = this.items.find((res) => res.id === auditLogId);
    setTimeout(() => {
      this.handleDownloadPdf();
    }, 800);
    return;
  }

  public getReceipt() {
    const streetAddress =
      this.receipt?.transaction_status.billingAddress?.streetAddress || '';
    const city = this.receipt?.transaction_status.billingAddress?.city || '';
    const country =
      this.receipt?.transaction_status.billingAddress?.country || '';

    const billingAddress = country
      ? `${streetAddress} ${city} ${country}`
      : 'N/A';

    const amount = this.receipt?.payment_amount
      ? this.receipt?.payment_amount / 100
      : 'N/A';
    const gstAmount = this.receipt?.gst_amount
      ? +(this.receipt?.gst_amount / 100).toFixed(2)
      : 'N/A';
    const gstPercentage =
      gstAmount > 0 && amount !== 'N/A' && gstAmount !== 'N/A'
        ? `(${Math.round((amount / (amount - gstAmount) - 1) * 100)}%)`
        : '';

    return {
      orderNumber: this.receipt?.order_number || 'N/A',
      updatedAt: this.receipt?.started_at
        ? dayjs(this.receipt?.started_at).format('D MMM YYYY, hh:mm A')
        : 'N/A',
      paymentCurrency: this.receipt?.payment_currency || 'N/A',
      productName: this.receipt?.product,
      price: this.receipt?.transaction_status.productPrice
        ? moneyParser({ value: this.receipt?.transaction_status.productPrice })
        : moneyParser({ value: 0 }),
      gst:
        gstAmount > 0 && this.receipt?.gst_amount
          ? moneyParser({ value: this.receipt?.gst_amount })
          : 'N/A',
      amount: this.receipt?.payment_amount
        ? moneyParser({ value: this.receipt?.payment_amount })
        : moneyParser({ value: 0 }),
      cardHolderName:
        _get(this.receipt?.txnResp, '.CardDetails.CardHolderName') || 'N/A',
      cardNumber:
        _get(this.receipt?.txnResp, 'CardDetails.MaskedCardNumber') || 'N/A',
      receiptNumber: _get(this.receipt?.txnResp, 'ReceiptNumber'),
      merchantNumber: _get(this.receipt?.txnResp, 'MerchantNumber') || '-',
      billingAddress,
      gstPercentage
    };
  }

  public handleDownloadPdf() {
    this.isDownloadingReceipt = true;

    setTimeout(() => {
      try {
        const transaction = document.getElementById('downloadReceipt');
        if (!transaction) {
          Toast.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: `Could not find the receipt element.`
          });

          this.isDownloadingReceipt = false;
          return;
        }
        const receipt = this.getReceipt();
        downloadReceiptPDF(receipt);
      } catch (error) {
        Toast.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          message: `Error generating PDF. ${error}`
        });
      } finally {
        this.isDownloadingReceipt = false;
      }
    }, 500);
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public formatDate(date: Date) {
    const result = dayjs(date).format('D MMMM YYYY');

    return result === 'Invalid Date' ? '-' : result;
  }

  public async handleOpenAuditLog(auditLogId: string, items: AuditLog[]) {
    this.$buefy.modal.open({
      parent: this,
      component: AuditLogModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        auditLogId,
        items
      }
    });
  }

  @isDifferent
  @isTruthy
  @Watch('isDownloadingReceipt')
  public handleDownloadReceipt() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Downloading receipt...`
    });
  }
}
