import dayjs from 'dayjs';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TableLayout, TDocumentDefinitions } from 'pdfmake/interfaces';

// Assign the vfs from pdfFonts
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

(pdfMake as any).fonts = {
  Oswald: {
    normal: 'https://fonts.cdnfonts.com/s/16359/Oswald-Regular.woff',
    bold: 'https://fonts.cdnfonts.com/s/16359/Oswald-Heavy.woff'
  },
  Helvetica: {
    normal:
      'https://cdn.jsdelivr.net/npm/helvetica-original@1.0.0/Light/Helvetica-Light.ttf',
    bold:
      'https://cdn.jsdelivr.net/npm/helvetica-original@1.0.0/Bold/Helvetica-Bold.ttf'
  },
  Roboto: {
    normal:
      'https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2',
    bold:
      'https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2'
  }
};

interface TransactionStatus {
  productPrice: number;
  gst: number;
  totalprice: number;
  date: Date;
}

interface AuditLog<T> {
  order_number: string;
  user: string;
  product: string;
  payment_currency: string;
  transaction_status: T;
  payment_status: string;
}

function formatDate(date: Date): string {
  const result: string = dayjs(date).format('D MMMM YYYY');
  return result === 'Invalid Date' ? '-' : result;
}

function extractTableData(filteredAuditLogParams: any): any[] {
  const body = [];

  body.push([
    { text: 'Order No', bold: true },
    { text: 'Customer', bold: true },
    { text: 'Product', bold: true },
    { text: 'Price', bold: true },
    { text: 'GST', bold: true },
    { text: 'Total Price', bold: true },
    { text: 'Date', bold: true },
    { text: 'Status', bold: true }
  ]);

  filteredAuditLogParams.items.forEach((log: AuditLog<TransactionStatus>) => {
    const rowData = [
      log.order_number || 'N/A',
      log.user || 'N/A',
      log.product || 'N/A',
      `${log.payment_currency} $${
        (log.transaction_status.productPrice / 100).toFixed(2) || 'N/A'
      }`,
      `${log.payment_currency} $${
        (log.transaction_status.gst / 100).toFixed(2) || 'N/A'
      }`,
      `${log.payment_currency} $${
        (log.transaction_status.totalprice / 100).toFixed(2) || 'N/A'
      }`,
      log.transaction_status.date || 'N/A',
      log.payment_status || 'N/A'
    ];
    body.push(rowData);
  });

  return body;
}

const tableLayout: TableLayout = {
  hLineWidth(i: number, node: any) {
    return i === 0 || i === node.table.body.length ? 1 : 0.1;
  },
  vLineWidth(i: number, node: any) {
    return i === 0 || i === node.table.widths.length ? 1 : 0;
  },
  hLineColor(i: number, node: any) {
    return i === 0 || i === node.table.body.length ? '#eeeeee' : '#dddddd';
  },
  vLineColor(i: number, node: any) {
    return i === 0 || i === node.table.widths.length ? '#eeeeee' : '#dddddd';
  }
};

function generatePdfContent(
  transactionTitle: string,
  statusText: string,
  periodText: string,
  priceRangeText: string,
  tableBody: any[]
): any[] {
  return [
    { text: transactionTitle, style: 'header' },
    {
      columns: [
        {
          stack: [
            { text: 'Status:', style: 'subheader' },
            { text: statusText, style: 'subheadertext' }
          ]
        },
        {
          stack: [
            { text: 'Period', style: 'subheader' },
            { text: periodText, style: 'subheadertext' }
          ]
        },
        {
          stack: [
            { text: 'Price Range:', style: 'subheader' },
            { text: priceRangeText, style: 'subheadertext' }
          ]
        }
      ]
    },
    {
      table: {
        headerRows: 1,
        widths: [
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto'
        ],
        body: tableBody.map((row, rowIndex) =>
          row.map((cell: any) => ({
            text: cell,
            alignment: 'center',
            margin: [3, 3, 3, 3],
            color: 'black',
            fillColor: rowIndex === 0 ? '#f6f6f6' : '#fbfbfb'
          }))
        )
      },
      layout: tableLayout,
      fontSize: 6
    }
  ];
}

export function generateAuditLogPDF(
  filteredAuditLogParams: any,
  pdfAuditLogs: any,
  callback: (error?: Error) => void
): void {
  try {
    const startDate = filteredAuditLogParams?.startDate;
    const endDate = filteredAuditLogParams?.endDate;
    const status = filteredAuditLogParams?.status;
    const minPrice = filteredAuditLogParams?.minTotalPrice;
    const maxPrice = filteredAuditLogParams?.maxTotalPrice;

    const transactionTitle = 'Transactions';
    const statusText = `${status}` || 'All Transaction Status';
    const periodText =
      startDate !== endDate && startDate && endDate
        ? `${startDate} - ${endDate}`
        : 'N/A';
    const priceRangeText =
      minPrice !== maxPrice && minPrice && maxPrice
        ? `$${minPrice} - $${maxPrice}`
        : 'N/A';

    const tableBody = extractTableData(pdfAuditLogs);
    const content = generatePdfContent(
      transactionTitle,
      statusText,
      periodText,
      priceRangeText,
      tableBody
    );

    const docDefinition: TDocumentDefinitions = {
      content,
      styles: {
        header: {
          fontSize: 30,
          bold: true,
          font: 'Oswald',
          color: '#00205b'
        },
        tstyle: {
          fontSize: 9
        },
        subheader: {
          fontSize: 9,
          font: 'Helvetica',
          bold: true,
          marginTop: 5,
          color: '#444f51'
        },
        subheadertext: {
          fontSize: 9,
          font: 'Helvetica',
          marginBottom: 20
        }
      },
      defaultStyle: {
        font: 'Helvetica',
        fontSize: 9,
        margin: 5,
        color: '#444f51'
      }
    };

    pdfMake
      .createPdf(docDefinition)
      .download(`Transactions_List_${formatDate(new Date())}.pdf`, () => {
        callback();
      });
    callback();
  } catch (err) {
    const error =
      err instanceof Error ? err : new Error('Unknown error occurred');
    callback(error);
  }
}
