import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, Style, TDocumentDefinitions } from 'pdfmake/interfaces';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

interface Receipt {
  orderNumber: string;
  receiptNumber?: string;
  updatedAt: string;
  merchantNumber: string;
  productName?: string;
  price?: string;
  gst?: string;
  currency?: string;
  amount?: string;
  cardHolderName?: string;
  cardNumber?: string;
  billingAddress?: string;
}

interface CustomStyle extends Style {
  font?: string;
  color?: string;
}

// Function to generate the document definition
export function generateReceiptPDF(receipt: Receipt): TDocumentDefinitions {
  const content: Content[] = [
    {
      text: 'Receipt',
      style: 'header',
      margin: [0, 0, 0, 20]
    },
    {
      table: {
        widths: ['*'],
        body: [
          [
            {
              columns: [
                {
                  width: '70%',
                  text: `Order # ${receipt.orderNumber}\n Receipt No. - ${
                    receipt.receiptNumber || ''
                  } `,
                  style: 'orderNumber'
                },
                {
                  width: '30%',
                  text: `${receipt.updatedAt}\nMerchant No. ${receipt.merchantNumber}`,
                  alignment: 'right',
                  fontSize: 10
                }
              ]
            }
          ],
          [
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 0.1,
                  lineColor: '#dbdbdb'
                }
              ],
              margin: [0, 10, 0, 5]
            }
          ],
          [
            {
              text: 'PURCHASE DETAILS',
              style: 'sectionHeader',
              margin: [0, 10, 0, 5]
            }
          ],
          [
            {
              table: {
                widths: ['40%', '15%', '15%', '15%', '15%'],
                body: [
                  [
                    { text: 'Product', style: 'tableHeader' },
                    { text: 'Price', style: 'tableHeader' },
                    { text: 'GST', style: 'tableHeader' },
                    { text: 'Currency', style: 'tableHeader' },
                    { text: 'Amount', style: 'tableHeader' }
                  ],
                  [
                    receipt.productName || 'N/A',
                    receipt.price || '$0.00',
                    receipt.gst || 'N/A',
                    receipt.currency || 'AUD$',
                    receipt.amount || 'AUD$0.00'
                  ]
                ]
              },
              layout: {
                hLineWidth: (i: number) => (i === 0 ? 0 : 0.1),
                hLineColor: () => '#dbdbdb',
                vLineWidth: () => 0,
                paddingLeft: () => 8,
                paddingRight: () => 8,
                paddingTop: () => 4,
                paddingBottom: () => 4
              }
            }
          ],
          [
            {
              text: 'CARD DETAILS',
              style: 'sectionHeader',
              margin: [0, 20, 0, 5]
            }
          ],
          [
            {
              stack: [
                {
                  text: 'Card Holder Name',
                  bold: true,
                  margin: [0, 5]
                },
                {
                  text: receipt.cardHolderName || 'N/A',
                  margin: [0, 5]
                },
                {
                  text: 'Card Number',
                  bold: true,
                  margin: [0, 5]
                },
                {
                  text: receipt.cardNumber || 'N/A',
                  margin: [0, 5]
                },
                {
                  text: 'Billing Address',
                  bold: true,
                  margin: [0, 5]
                },
                {
                  text: receipt.billingAddress || 'N/A',
                  margin: [0, 5]
                }
              ]
            }
          ]
        ]
      },
      layout: {
        hLineWidth: (i: number, node: any) =>
          i === 0 || i === node.table.body.length ? 0.1 : 0,
        vLineWidth: (i: number, node: any) =>
          i === 0 || i === node.table.widths.length ? 0.1 : 0,
        hLineColor: () => '#dbdbdb',
        vLineColor: () => '#dbdbdb',
        paddingLeft: () => 8,
        paddingRight: () => 8,
        paddingTop: () => 8,
        paddingBottom: () => 8
      }
    }
  ];

  const docDefinition: TDocumentDefinitions = {
    pageMargins: [40, 60, 40, 60],
    content,
    styles: {
      header: {
        fontSize: 30,
        bold: true,
        font: 'Helvetica',
        margin: [0, 0, 0, 10],
        color: '#444f51'
      },
      orderNumber: {
        fontSize: 12,
        font: 'Helvetica',
        bold: true,
        color: '#444f51'
      },
      sectionHeader: {
        fontSize: 12,
        bold: true,
        font: 'Helvetica',
        color: '#444f51'
      },
      tableHeader: {
        fontSize: 11,
        bold: true,
        font: 'Helvetica',
        color: '#444f51',
        margin: [0, 5, 0, 5]
      }
    },
    defaultStyle: {
      font: 'Helvetica',
      color: '#444f51',
      fontSize: 10
    }
  };

  return docDefinition;
}

// Function to create and download the PDF
export function downloadReceiptPDF(receipt: Receipt): void {
  const docDefinition = generateReceiptPDF(receipt);
  pdfMake.createPdf(docDefinition).download(`${receipt.orderNumber}.pdf`);
}
