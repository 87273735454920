



















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import SearchBox from '@/views/AdminAuditLog/components/SearchBox.vue';
import { Action, State } from 'vuex-class';
import {
  AuditLog,
  ExportFileAuditLogPayload,
  FilteredAuditPayload,
  GetAuditLogFilterPayload,
  GetAuditLogPayload
} from '@/store/modules/audit-log/types/audit-log.types';
import { RootState } from '@/store/store';
import { PaginatedResponse } from '@/store/types/general.types';
import { get as _get } from 'lodash';
import { pluckNewValue } from '@/jbi-shared/util/pluck.rx-operator';
import { filter, pluck } from 'rxjs/operators';
import dayjs from 'dayjs';
import PaginatedAuditLog from './components/PaginatedAuditLog.vue';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Debounce } from '../../jbi-shared/util/debounce.vue-decorator';
import { moneyParser as _moneyParser } from '../../utils/parser.util';
import { PaymentStatusTypes } from '../../store/types/general.types';
import { AxiosError } from 'axios';
import { generateAuditLogPDF } from '@/Export/AuditLogGeneratePDF';

const initialState = {
  orderNo: '',
  customer: '',
  product: '',
  status: '',
  date: [],
  totalPrice: '',
  productPrice: ''
};

@Component({
  components: {
    Container,
    SearchBox,
    PaginatedAuditLog
  }
})
export default class AdminAuditLog extends Vue {
  public page: number = 1;
  public perPage = 50;
  public sortColumn: string = 'orderId';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredAuditLogParams: FilteredAuditPayload & { dates?: any[] } = {
    ...initialState
  };
  isDownloading = false;

  @Action('auditLog/getAuditLog')
  public getAuditLog!: (params: GetAuditLogPayload) => Promise<any>;

  @Action('auditLog/exportPdfAuditLog')
  public exportPdfAuditLog!: (
    params: ExportFileAuditLogPayload
  ) => Promise<any>;

  @Action('auditLog/exportCsvAuditLog')
  public exportCsvAuditLog!: (
    params: ExportFileAuditLogPayload
  ) => Promise<any>;

  @State(({ auditLog }: RootState) => auditLog.auditLogs)
  public allAuditLogs!: PaginatedResponse<GetAuditLogPayload>;

  @State(({ auditLog }: RootState) => auditLog.pdfAuditLogs)
  public pdfAuditLogs!: PaginatedResponse<GetAuditLogPayload>;

  get maxPrice() {
    const result = !this.filteredAuditLogParams.maxTotalPrice
      ? 0
      : Math.ceil(
          parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
        ).toString();
    return result;
  }

  get minPrice() {
    const result = !this.filteredAuditLogParams.minTotalPrice
      ? 0
      : Math.ceil(
          parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
        ).toString();
    return result;
  }

  get auditLogs() {
    return {
      items: _get(this.allAuditLogs, 'items', []),
      totalItems: _get(this.allAuditLogs, 'totalItems', 0)
    };
  }

  get exportPdfAuditLogs() {
    return {
      items: _get(this.pdfAuditLogs, 'items', []),
      totalItems: _get(this.pdfAuditLogs, 'totalItems', 0)
    };
  }

  public formatDate(date: Date) {
    const result = dayjs(date).format('D MMMM YYYY');

    return result === 'Invalid Date' ? '-' : result;
  }

  public getTransactionStatus(paymentStatus: PaymentStatusTypes | string) {
    return paymentStatus === PaymentStatusTypes.SUCCESS
      ? 'Successful'
      : paymentStatus === PaymentStatusTypes.FAILED
      ? 'Failed'
      : 'Pending';
  }

  public async handleDownloadCsv() {
    if (
      this.filteredAuditLogParams.maxTotalPrice &&
      this.filteredAuditLogParams.minTotalPrice &&
      Math.ceil(parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100) <
        Math.ceil(parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100)
    ) {
      return;
    }
    this.isDownloading = true;
    await this.exportCsvAuditLog({
      orderNo: this.filteredAuditLogParams.orderNo || undefined,
      customer: this.filteredAuditLogParams.customer || undefined,
      product: this.filteredAuditLogParams.product || undefined,
      minTotalPrice: this.filteredAuditLogParams.minTotalPrice
        ? Math.ceil(
            parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
          ).toString()
        : undefined,
      maxTotalPrice: this.filteredAuditLogParams.maxTotalPrice
        ? Math.ceil(
            parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
          ).toString()
        : undefined,
      startDate: this.filteredAuditLogParams.startDate || undefined,
      endDate: this.filteredAuditLogParams.endDate || undefined,
      status: this.filteredAuditLogParams.status || undefined
    });
    this.isDownloading = false;
  }

  public async handleDownloadPdf() {
    const orderNo = this.filteredAuditLogParams.orderNo || undefined;
    const customer = this.filteredAuditLogParams.customer || undefined;
    const product = this.filteredAuditLogParams.product || undefined;

    const minTotalPrice = this.filteredAuditLogParams.minTotalPrice
      ? Math.ceil(
          parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
        ).toString()
      : undefined;

    const maxTotalPrice = this.filteredAuditLogParams.maxTotalPrice
      ? Math.ceil(
          parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
        ).toString()
      : undefined;

    const startDate = this.filteredAuditLogParams.startDate || undefined;
    const endDate = this.filteredAuditLogParams.endDate || undefined;
    const status = this.filteredAuditLogParams.status;
    const downloadPDF = true;

    const filteredParams = {
      orderNo,
      customer,
      product,
      minTotalPrice,
      maxTotalPrice,
      startDate,
      endDate,
      status,
      downloadPDF
    };

    this.isDownloading = true;
    document.body.style.overflow = 'hidden';
    await this.exportPdfAuditLog(filteredParams);

    try {
      generateAuditLogPDF(
        this.filteredAuditLogParams,
        this.pdfAuditLogs,
        (error) => {
          if (error) {
            Toast.open({
              queue: true,
              type: 'is-danger',
              position: 'is-top',
              message: `Error generating PDF: ${error}`
            });
          } else {
            Toast.open({
              queue: true,
              type: 'is-danger',
              position: 'is-top',
              message: 'PDF generated successfully'
            });
          }
        }
      );
    } catch (error) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: `Error during PDF export: ${error}`
      });
    } finally {
      this.isDownloading = false;
      document.body.style.overflow = 'inherit';
    }
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      orderNo:
        (this.$route.query.orderNo as string) ||
        this.filteredAuditLogParams.orderNo,
      customer:
        (this.$route.query.customer as string) ||
        this.filteredAuditLogParams.customer,
      product:
        (this.$route.query.product as string) ||
        this.filteredAuditLogParams.product,
      minTotalPrice:
        (this.$route.query.minTotalPrice as string) ||
        (this.filteredAuditLogParams.minTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()
          : undefined),
      maxTotalPrice:
        (this.$route.query.maxTotalPrice as string) ||
        (this.filteredAuditLogParams.maxTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()
          : undefined),
      startDate:
        (this.$route.query.startDate as string) ||
        this.filteredAuditLogParams.startDate,
      endDate:
        (this.$route.query.endDate as string) ||
        this.filteredAuditLogParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredAuditLogParams.status,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getAuditLog(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        orderNo:
          (this.$route.query.orderNo as string) ||
          (this.filteredAuditLogParams.orderNo as string),
        customer:
          (this.$route.query.customer as string) ||
          this.filteredAuditLogParams.customer!,
        product:
          (this.$route.query.product as string) ||
          this.filteredAuditLogParams.product!,
        minTotalPrice:
          (this.$route.query.minTotalPrice as string) ||
          (this.filteredAuditLogParams.minTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()),
        maxTotalPrice:
          (this.$route.query.maxTotalPrice as string) ||
          (this.filteredAuditLogParams.maxTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()),
        startDate:
          (this.$route.query.startDate as string) ||
          this.filteredAuditLogParams.startDate!,
        endDate:
          (this.$route.query.endDate as string) ||
          this.filteredAuditLogParams.endDate!,
        status:
          (this.$route.query.status as string) ||
          this.filteredAuditLogParams.status!,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public handleResetFilter() {
    this.filteredAuditLogParams = { ...initialState };
    this.$router.push({
      path: this.$route.path,
      query: {
        orderNo: this.filteredAuditLogParams.orderNo as string,
        customer: this.filteredAuditLogParams.customer!,
        product: this.filteredAuditLogParams.product!,
        minTotalPrice:
          this.filteredAuditLogParams.minTotalPrice! &&
          Math.ceil(
            parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
          ).toString(),
        maxTotalPrice:
          this.filteredAuditLogParams.maxTotalPrice! &&
          Math.ceil(
            parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
          ).toString(),
        status: this.filteredAuditLogParams.status!,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  @Debounce(500)
  public handleFilter() {
    if (
      this.filteredAuditLogParams.maxTotalPrice &&
      this.filteredAuditLogParams.minTotalPrice &&
      Math.ceil(parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100) <
        Math.ceil(parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100)
    ) {
      return;
    }
    this.perPage = +this.$route.query.limit;
    let queryFilter: GetAuditLogFilterPayload & { dates?: any[] } = {
      orderNo: this.$route.query.orderNo as string,
      customer: this.$route.query.customer as string,
      product: this.$route.query.product as string,
      minTotalPrice: this.$route.query.minTotalPrice as string,
      maxTotalPrice: this.$route.query.maxTotalPrice as string,
      startDate: this.$route.query.startDate as string,
      endDate: this.$route.query.endDate as string,
      status: this.$route.query.status as string
    };
    if (this.$route.query.startDate && this.$route.query.endDate) {
      queryFilter = {
        ...queryFilter,
        dates: [
          new Date(this.$route.query.startDate as string),
          new Date(this.$route.query.endDate as string)
        ],
        startDate: dayjs(this.$route.query.startDate as string).format(
          'YYYY-MM-DD'
        ),
        endDate: dayjs(this.$route.query.endDate as string).format('YYYY-MM-DD')
      };
    }

    if (
      JSON.stringify(queryFilter) !==
      JSON.stringify(this.filteredAuditLogParams)
    ) {
      this.page = 1;
      this.getAuditLog({
        orderNo: this.filteredAuditLogParams.orderNo || undefined,
        customer: this.filteredAuditLogParams.customer || undefined,
        product: this.filteredAuditLogParams.product || undefined,
        minTotalPrice: this.filteredAuditLogParams.minTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()
          : undefined,
        maxTotalPrice: this.filteredAuditLogParams.maxTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()
          : undefined,
        startDate: this.filteredAuditLogParams.startDate || undefined,
        endDate: this.filteredAuditLogParams.endDate || undefined,
        status: this.filteredAuditLogParams.status || undefined,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      });

      this.$router.push({
        path: this.$route.path,
        query: {
          orderNo: this.filteredAuditLogParams.orderNo as string,
          customer: this.filteredAuditLogParams.customer!,
          product: this.filteredAuditLogParams.product!,
          minTotalPrice:
            this.filteredAuditLogParams.minTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString(),
          maxTotalPrice:
            this.filteredAuditLogParams.maxTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString(),
          status: this.filteredAuditLogParams.status!,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
      if (this.filteredAuditLogParams.dates) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            startDate: this.filteredAuditLogParams.startDate as string,
            endDate: this.filteredAuditLogParams.endDate as string
          }
        });
      }
    }
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;
    const params = {
      orderNo:
        (this.$route.query.orderNo as string) ||
        this.filteredAuditLogParams.orderNo,
      customer:
        (this.$route.query.customer as string) ||
        this.filteredAuditLogParams.customer,
      product:
        (this.$route.query.product as string) ||
        this.filteredAuditLogParams.product,
      minTotalPrice:
        (this.$route.query.minTotalPrice as string) ||
        (this.filteredAuditLogParams.minTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()
          : undefined),
      maxTotalPrice:
        (this.$route.query.maxTotalPrice as string) ||
        (this.filteredAuditLogParams.maxTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()
          : undefined),
      startDate:
        (this.$route.query.startDate as string) ||
        this.filteredAuditLogParams.startDate,
      endDate:
        (this.$route.query.endDate as string) ||
        this.filteredAuditLogParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredAuditLogParams.status,
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };

    await this.getAuditLog(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        orderNo:
          (this.$route.query.orderNo as string) ||
          (this.filteredAuditLogParams.orderNo as string),
        customer:
          (this.$route.query.customer as string) ||
          this.filteredAuditLogParams.customer!,
        product:
          (this.$route.query.product as string) ||
          this.filteredAuditLogParams.product!,
        minTotalPrice:
          (this.$route.query.minTotalPrice as string) ||
          (this.filteredAuditLogParams.minTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()),
        maxTotalPrice:
          (this.$route.query.maxTotalPrice as string) ||
          (this.filteredAuditLogParams.maxTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()),
        startDate:
          (this.$route.query.startDate as string) ||
          this.filteredAuditLogParams.startDate!,
        endDate:
          (this.$route.query.endDate as string) ||
          this.filteredAuditLogParams.endDate!,
        status:
          (this.$route.query.status as string) ||
          this.filteredAuditLogParams.status!,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }
  public handleResetQuery() {
    const params = {
      orderNo:
        (this.$route.query.orderNo as string) ||
        this.filteredAuditLogParams.orderNo,
      customer:
        (this.$route.query.customer as string) ||
        this.filteredAuditLogParams.customer,
      product:
        (this.$route.query.product as string) ||
        this.filteredAuditLogParams.product,
      minTotalPrice:
        (this.$route.query.minTotalPrice as string) ||
        (this.filteredAuditLogParams.minTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()
          : undefined),
      maxTotalPrice:
        (this.$route.query.maxTotalPrice as string) ||
        (this.filteredAuditLogParams.maxTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()
          : undefined),
      startDate:
        (this.$route.query.startDate &&
          dayjs(this.$route.query.startDate as string).format('YYYY-MM-DD')) ||
        this.filteredAuditLogParams.startDate,
      endDate:
        (this.$route.query.endDate &&
          dayjs(this.$route.query.endDate as string).format('YYYY-MM-DD')) ||
        this.filteredAuditLogParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredAuditLogParams.status,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };

    return this.getAuditLog(params);
  }

  public mounted() {
    this.$watchAsObservable('filteredAuditLogParams', { deep: true })
      .pipe(pluckNewValue(), filter(Boolean), pluck('dates'), filter(Boolean))
      .subscribe((dates: any) => {
        if (dates.length === 0) {
          this.filteredAuditLogParams.startDate = '';
          this.filteredAuditLogParams.endDate = '';
        } else {
          const [startAt, endAt] = dates as Date[];
          this.filteredAuditLogParams.startDate = dayjs(startAt).format(
            'YYYY-MM-DD'
          );
          this.filteredAuditLogParams.endDate = dayjs(endAt).format(
            'YYYY-MM-DD'
          );
        }
      });

    this.filteredAuditLogParams = {
      orderNo:
        (this.$route.query.orderNo as string) ||
        this.filteredAuditLogParams.orderNo,
      customer:
        (this.$route.query.customer as string) ||
        this.filteredAuditLogParams.customer,
      product:
        (this.$route.query.product as string) ||
        this.filteredAuditLogParams.product,
      minTotalPrice:
        (this.$route.query.minTotalPrice as string) ||
        (this.filteredAuditLogParams.minTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()
          : undefined),
      maxTotalPrice:
        (this.$route.query.maxTotalPrice as string) ||
        (this.filteredAuditLogParams.maxTotalPrice
          ? Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()
          : undefined),
      startDate:
        (this.$route.query.startDate as string) ||
        this.filteredAuditLogParams.startDate,
      endDate:
        (this.$route.query.endDate as string) ||
        this.filteredAuditLogParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredAuditLogParams.status
    };
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.filteredAuditLogParams = {
        ...this.filteredAuditLogParams,
        dates: [
          new Date(this.$route.query.startDate as string),
          new Date(this.$route.query.endDate as string)
        ]
      };
    }

    this.$router.push({
      path: this.$route.path,
      query: {
        orderNo:
          (this.$route.query.orderNo as string) ||
          (this.filteredAuditLogParams.orderNo as string),
        customer:
          (this.$route.query.customer as string) ||
          this.filteredAuditLogParams.customer!,
        product:
          (this.$route.query.product as string) ||
          this.filteredAuditLogParams.product!,
        minTotalPrice:
          (this.$route.query.minTotalPrice as string) ||
          (this.filteredAuditLogParams.minTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.minTotalPrice) * 100
            ).toString()),
        maxTotalPrice:
          (this.$route.query.maxTotalPrice as string) ||
          (this.filteredAuditLogParams.maxTotalPrice! &&
            Math.ceil(
              parseFloat(this.filteredAuditLogParams.maxTotalPrice) * 100
            ).toString()),
        startDate:
          (this.$route.query.startDate as string) ||
          this.filteredAuditLogParams.startDate!,
        endDate:
          (this.$route.query.endDate as string) ||
          this.filteredAuditLogParams.endDate!,
        status:
          (this.$route.query.status as string) ||
          this.filteredAuditLogParams.status!,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
    this.perPage = +this.$route.query.limit || this.perPage;
    this.page = +this.$route.query.page || this.page;
    this.handleResetQuery();
  }

  @isDifferent
  @isTruthy
  @Watch('isDownloading')
  public handleDownloadTransactions() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Downloading transactions list...`
    });
  }

  @isDifferent
  @isTruthy
  @Watch('apiState.getAuditLogs.error')
  public watchUpdateMeError(error: AxiosError) {
    if (!error) {
      return;
    }
    this.$buefy.toast.open({
      message: _get(error, 'response.data.message', 'Unknown Error'),
      type: 'is-danger',
      position: 'is-top'
    });
  }
}
